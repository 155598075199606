<template>
  <div>
    <b-button @click="clicks" variant="primary">{{
      $t("account.UploadLogos")
    }}</b-button>

    <!-- Table Container Card -->

    <b-modal
      id="modal-sm-account-logo"
      no-close-on-esc
      no-close-on-backdrop
      centered
      size="lg"
      modal-class="no-header-modal"
      :hide-header="true"
    >
      <div style="padding: 0.8rem 1.4rem"></div>
      <b-card-text class="account-logo-modal">
        <div class="new-invite-modal-title">
          {{ $t("account.UploadPhotos") }}
        </div>
        <div class="you-invite">
          {{ $t("account.Description") }}
        </div>

        <b-row class="mt-1">
          <b-col md="12" lg="7" sm="12" style="text-align: center">
            <b-card>
              <b-row class="mt-1"
                ><b-col cols="12"
                  ><b class="text-primary"
                    >{{ $t("account.Expanded") }}
                  </b></b-col
                ></b-row
              >
              <b-row class="mt-1">
                <b-col
                  cols="6"
                  v-for="(file, index) in fileExpanded"
                  :key="file.key"
                >
                  <span>
                    <b class="text-primary"
                      >{{
                        ["light_collapsed", "light_expanded"].includes(file.key)
                          ? $t("account.LightMode")
                          : ""
                      }}
                      {{
                        ["dark_collapsed", "dark_expanded"].includes(file.key)
                          ? $t("account.DarkMode")
                          : ""
                      }}
                    </b>
                  </span>

                  <AccountLogo
                    :class="
                      file.file_id
                        ? `logo-outer  `
                        : `logo-outer logo-outer-blank`
                    "
                    v-model="fileExpanded[index]"
                    :fileUrls="fileUrls"
                    :img="default_icon"
                    :cropHeight="35"
                    :cropWidth="135"
                /></b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="12" lg="5" sm="12" style="text-align: center">
            <b-card>
              <b-row class="mt-1"
                ><b-col cols="12"
                  ><b class="text-primary">
                    {{ $t("account.Collapsed") }}</b
                  ></b-col
                ></b-row
              >
              <b-row class="mt-1">
                <b-col
                  cols="6"
                  v-for="(file, index) in fileCollapsed"
                  :key="file.key"
                >
                  <span style="margin-bottom: 10px">
                    <b class="text-primary"
                      >{{
                        ["light_collapsed", "light_expanded"].includes(file.key)
                          ? $t("account.LightMode")
                          : ""
                      }}
                      {{
                        ["dark_collapsed", "dark_expanded"].includes(file.key)
                          ? $t("account.DarkMode")
                          : ""
                      }}
                    </b>
                  </span>
                  <AccountLogo
                    :fileUrls="fileUrls"
                    :class="
                      file.file_id
                        ? `logo-outer logo-outer-width`
                        : `logo-outer logo-outer-blank logo-outer-width`
                    "
                    v-model="fileCollapsed[index]"
                    :img="default_icon"
                    :cropHeight="35"
                    :cropWidth="35"
                /></b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <!--  -->

        <b-row class="d-flexd-flex justify-content-center mb-64 mtt-50">
          <div class="">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save"
              :disabled="isInValidFiles"
              class="mt-2 mr-1"
            >
              {{ $t("user.Save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mt-2"
              @click="close"
            >
              {{ $t("user.Cancel") }}
            </b-button>
          </div>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BCard, BButton, BModal, BCardText, BCol, BRow } from "bootstrap-vue";
import AccountLogo from "@/layouts/components/ConsumerOnboarding/AccountLogo.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BButton,
    BCardText,
    BModal,
    BCol,
    AccountLogo,
    BRow
  },
  directives: {
    Ripple
  },
  data() {
    return {
      default_icon: `${this.getCurrentPath()}/app/images/upload_acc.svg`,
      isInValidFiles: false,
      fileExpanded: [
        {
          url: "",
          file_id: "",
          key: "light_expanded"
        },
        {
          url: "",
          file_id: "",
          key: "dark_expanded"
        }
      ],
      fileCollapsed: [
        {
          url: "",
          file_id: "",
          key: "light_collapsed"
        },
        {
          url: "",
          file_id: "",
          key: "dark_collapsed"
        }
      ],
      fileUrls: []
    };
  },
  mounted() {
    this.fileCollapsed = this.value.filter((e) =>
      ["light_collapsed", "dark_collapsed"].includes(e.key)
    );
    this.fileExpanded = this.value.filter((e) =>
      ["light_expanded", "dark_expanded"].includes(e.key)
    );
    this.isInValidUrls();
  },
  props: ["value"],
  watch: {
    fileCollapsed: {
      deep: true,
      handler(newOptions) {
        this.isInValidUrls();
      }
    },
    fileExpanded: {
      deep: true,
      handler(newOptions) {
        this.isInValidUrls();
      }
    },
    value() {
      this.fileCollapsed = this.value.filter((e) =>
        ["light_collapsed", "dark_collapsed"].includes(e.key)
      );
      this.fileExpanded = this.value.filter((e) =>
        ["light_expanded", "dark_expanded"].includes(e.key)
      );
    }
  },
  methods: {
    clicks(e) {
      this.$bvModal.show("modal-sm-account-logo");
    },
    close() {
      this.$bvModal.hide("modal-sm-account-logo");
    },
    save() {
      this.$emit("input", [...this.fileCollapsed, ...this.fileExpanded]);
      this.close();
    },
    isInValidUrls() {
      const fileCodes = [...this.fileCollapsed, ...this.fileExpanded].filter(
        (e) => e.file_id != ""
      );
      this.fileUrls = [...this.fileCollapsed, ...this.fileExpanded];

      if (![0, 4].includes(fileCodes.length)) {
        this.isInValidFiles = true;
      } else {
        this.isInValidFiles = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.you-invite {
  font-size: 14px;
  line-height: 21px;

  margin-top: 14px;
}
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}
.logo-outer {
  position: relative;
  border: 1px solid var(--primary);
  border-radius: 6px;
  height: 100px;
  top: 10px;
}
.logo-outer-width {
  width: 60%;
  left: 25%;
}
.logo-outer-blank {
  border: 2px dashed var(--secondary);
}
@media only screen and (max-width: 991px) {
  .account-logo-modal {
    max-height: 65vh;
    overflow-y: auto;
    padding-right: 20px;
    .card-body {
      padding: 0.5rem;
    }
    .mtt-50 {
      margin-top: 0px;
      margin-bottom: 5px;
    }
  }
}
</style>
