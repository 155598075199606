<template>
  <div>
    <div class="delete-icon" v-if="value.url">
      <feather-icon
        icon="TrashIcon"
        @click="removeLogo"
        class="text-white curserPointer"
        v-if="value.url"
      />
    </div>

    <div class="icon-position">
      <b-img
        :class="value.url ? 'profile-pic' : 'default-icon'"
        rounded
        :src="value.url ? value.url : img"
      />

      <div class="icon-overlay" v-if="value.url">
        <!-- Add your icon here -->
        <feather-icon
          icon="EyeIcon"
          size="40"
          class="text-primary curserPointer"
          @click="viewClick(value.key)"
        />
      </div>
      <b-form-file
        v-model="logoFile"
        :class="
          value.url
            ? 'custom-file-input d-none curserPointer'
            : 'custom-file-input display-block curserPointer'
        "
        accept="image/*"
        @change="onFileSelect"
      />
      <b-modal
        v-model="modalShow"
        centered
        size="lg"
        modal-class="no-header-modal"
      >
        <!--  :dragMode="move"  comment because getting of error  -->
        <VueCropper
          :aspectRatio="cropWidth / cropHeight"
          :autoCropArea="0.65"
          :restore="false"
          :guides="false"
          :center="false"
          :highlight="false"
          :cropBoxMovable="true"
          :cropBoxResizable="false"
          :toggleDragModeOnDblclick="false"
          :fixedCropBox="true"
          v-show="selectedFile"
          ref="cropper"
          :src="selectedFile"
          alt="Source Image"
          :min-crop-box-width="cropWidth"
          :min-crop-box-height="cropHeight"
        ></VueCropper>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          :disabled="showLoading"
          @click="saveImage()"
        >
          {{ $t("account.Crop") }}</b-button
        >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          class="mt-2"
          @click="cancelImage()"
          text
        >
          {{ $t("account.Cancel") }}</b-button
        >
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BImg, BFormGroup, BButton, BFormFile } from "bootstrap-vue";

import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import Ripple from "vue-ripple-directive";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import FileService from "@/libs/api/file-service";
import { ref } from "@vue/composition-api";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    Loader,
    BButton,
    ToastificationContent,
    BFormGroup,
    BImg,
    BFormFile,
    VueCropper
  },
  data() {
    return {
      show: false,
      fileCode: "",
      logoFile: null,
      url: "",
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      files: "",
      modalShow: false,
      showLoading: false
    };
  },
  directives: {
    Ripple
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    };
  },

  mounted() {},

  props: ["value", "img", "cropWidth", "cropHeight", "fileUrls"],
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    viewClick(key) {
      document.body.classList.add("view-logo");
      store.commit("device/setIcons", this.fileUrls);
      setTimeout(() => {
        document.body.classList.remove("view-logo");
        store.commit("device/setIcons", []);
      }, 2000);
      if (key === "light_expanded") {
        const datta = store.state.verticalMenu.isVerticalMenuCollapsed;
        if (store.state.verticalMenu.isVerticalMenuCollapsed) {
          store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
          setTimeout(() => {
            store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
          }, 2000);
        }
        if (localStorage.getItem("vuexy-skin") === "dark") {
          document.body.classList.remove("dark-layout");
          setTimeout(() => {
            document.body.classList.add("dark-layout");
          }, 2000);
        }
      } else if (key === "dark_expanded") {
        if (store.state.verticalMenu.isVerticalMenuCollapsed) {
          store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
          setTimeout(() => {
            store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
          }, 2000);
        }
        if (localStorage.getItem("vuexy-skin") === "light") {
          document.body.classList.add("dark-layout");
          setTimeout(() => {
            document.body.classList.remove("dark-layout");
          }, 2000);
        }
      } else if (key === "light_collapsed") {
        if (!store.state.verticalMenu.isVerticalMenuCollapsed) {
          store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
          setTimeout(() => {
            store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
          }, 2000);
        }

        if (localStorage.getItem("vuexy-skin") === "dark") {
          document.body.classList.remove("dark-layout");
          setTimeout(() => {
            document.body.classList.add("dark-layout");
          }, 2000);
        }
      } else if (key === "dark_collapsed") {
        if (!store.state.verticalMenu.isVerticalMenuCollapsed) {
          store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", true);
          setTimeout(() => {
            store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
          }, 2000);
        }
        if (localStorage.getItem("vuexy-skin") === "light") {
          document.body.classList.add("dark-layout");
          setTimeout(() => {
            document.body.classList.remove("dark-layout");
          }, 2000);
        }
      }
      //  document.body.classList.add("menu-collapsed");
    },

    async saveImage() {
      const fileName =
        (this.profileFile && this.profileFile.name) ||
        Math.random().toString(36).slice(2, 7);
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      let newValue = this.dataURLtoFile(this.cropedImage, fileName);
      if (newValue) {
        this.showLoading = true;
        let data = new FormData();
        let file = newValue;

        data.append("name", fileName);
        data.append("file", file);
        new FileService()
          .fileApi(data)
          .then((res) => {
            if (res && res.success) {
              this.fileCode = res.code;
              this.url = res.url;

              this.$emit("input", {
                url: res.url,
                file_id: res.code,
                key: this.value.key,
                label: this.value.label
              });
              this.modalShow = false;
            } else if (res && !res.success && res.err && res.err.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.err.name,
                  text: res.err.message,
                  icon: "EditIcon",
                  variant: "error"
                }
              });
            }
            this.showLoading = false;
          })
          .catch((err) => {
            this.showLoading = false;
            this.showModal = false;
          });
      }
    },
    async cancelImage() {
      this.modalShow = false;
    },
    removeLogo() {
      this.$emit("input", {
        url: "",
        file_id: "",
        key: this.value.key,
        label: this.value.label
      });
    },
    onFileSelect(e) {
      const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
      this.mime_type = file.type;
      var fileSize = Math.round(file.size / 1024);

      if (fileSize >= constants.MAX_FILE_SIZE_LIMIT * 1024) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "",
            text: this.$t("profile.fileUploadLimitMessage", {
              limit: constants.MAX_FILE_SIZE_LIMIT
            }),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return;
      }
      if (
        file &&
        file.type &&
        !["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      ) {
        setTimeout(() => {
          this.resetImg();
        }, 200);

        this.$toast({
          component: ToastificationContent,
          props: {
            text: this.$t("errors.AllowedImagesError"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });

        return false;
      }
      this.modalShow = true;
      if (typeof FileReader === "function") {
        this.dialog = true;
        const reader = new FileReader();
        reader.onload = (event) => {
          this.selectedFile = event.target.result;
          this.$refs.cropper.replace(this.selectedFile);
        };
        reader.readAsDataURL(file);
        this.$bvModal.show("image-upload");
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("unit.FileReaderNotSupported"),
            text: this.$t("unit.FileReaderNotSupported"),
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
// Overrides user variable
@import "~@core/scss/base/components/include";
.upload-logo {
  .custom-file-label {
    background-color: transparent;
    cursor: pointer;
  }
}
.default-language {
  .vs__dropdown-option--selected {
    .text-body {
      color: #fff !important;
    }
  }
}
.profile-pic {
  width: 160px;
  height: 41px;
}
.default-icon {
  max-height: 80px;
  width: 100%;
}
.custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.icon-position {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 10px; /* Adjust padding as needed */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a transition effect for hover */
}

.icon-overlay:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Hover background color */
}

display-block {
  display: inline-block;
}
.delete-icon {
  position: absolute;
  background-color: #ff4141;
  height: 30px;
  width: 30px;
  right: -15px;
  top: -15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
@media only screen and (max-width: 667px) {
  .profile-pic {
    max-width: 100%;
    height: auto;
  }
}
</style>
