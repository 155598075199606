<template>
  <div>
    <b-form-group :label="$t('dateTimeFormat.heading')">
      <div class="date-input d-flex" v-b-modal.modal-date>
        <div class="display-format">
          <span v-if="!showDateTimeFormats">{{
            $t("dateTimeFormat.InputBrief")
          }}</span>
          <span v-else> {{ showDateTimeFormats }}</span>
        </div>
        <div class="p-1">
          <feather-icon size="16" icon="CalendarIcon" />
        </div>
      </div>

      <b-modal
        id="modal-date"
        :title="$t('dateTimeFormat.Title')"
        centered
        hide-footer
        :no-close-on-backdrop="true"
        @hidden="saveForm()"
        modal-class="date-modal"
      >
        <div class="text-center mb-1">
          {{ $t("dateTimeFormat.Brief") }}
        </div>
        <b-form-group :label="$t('dateTimeFormat.Date')">
          <v-select
            :options="dateFormatOptions"
            v-model="value.date_format"
            :reduce="(option) => option.key"
            :placeholder="$t('dateTimeFormat.datePlaceholder')"
          >
            <template #option="{ key, value }">
              <p class="option">
                <span> {{ key }}</span
                ><span>{{ value }}</span>
              </p>
            </template>
            <template #selected-option="{ key, value }">
              <p class="selected-option">
                <span> {{ key }}</span
                ><span>{{ value }}</span>
              </p>
            </template>
          </v-select>

          <b-form-input
            class="mt-1"
            type="text"
            v-model="value.custom_date"
            placeholder="Enter custom format"
            v-if="value.date_format == 'Custom'"
          ></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('dateTimeFormat.Time')">
          <v-select
            :options="timeFormatOptions"
            v-model="value.time_format"
            :reduce="(option) => option.key"
            :placeholder="$t('dateTimeFormat.timePlaceholder')"
          >
            <template #option="{ key, value }">
              <p class="option">
                <span>{{ key }}</span
                ><span>{{ value }}</span>
              </p>
            </template>
            <template #selected-option="{ key, value }">
              <p class="selected-option">
                <span>{{ key }}</span
                ><span>{{ value }}</span>
              </p>
            </template>
          </v-select>
          <b-form-input
            class="mt-1"
            type="text"
            v-model="value.custom_time"
            placeholder="Enter custom format"
            v-if="value.time_format == 'Custom'"
          ></b-form-input>
        </b-form-group>
        <div>
          <validation-provider
            #default="validationContext"
            :name="$t('dateTimeFormat.TimeZone')"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="$t('dateTimeFormat.TimeZone') + '*'"
              class="is-valid"
            >
              <v-select
                id="vue-select"
                class="timesize"
                :disabled="!isEditable"
                :class="!isEditable ? 'disabled' : ''"
                v-model="value.timezone"
                :options="timeZone.option"
                :reduce="(option) => option.value"
                label="label"
                placeholder="Select Timezone"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="save-btn">
          <b-button variant="primary" @click="saveForm()">{{
            $t("dateTimeFormat.closeButton")
          }}</b-button>
        </div>
      </b-modal>
    </b-form-group>
  </div>
</template>

<script>
import {
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import constants from "@/utils/constants";
import vSelect from "vue-select";
import moment from "moment-timezone";

export default {
  components: {
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormInvalidFeedback
  },
  data() {
    const { getValidationState } = formValidation();
    return {
      getValidationState,
      isEditable: true,
      timeZone: {
        option: constants.TZStrings
      },
      Custom: "",
      Custom: "",
      selectedDateFormats: {}
    };
  },
  watch: {
    value: {
      handler: function (newVal, oldVal) {
        if (newVal.date_format !== oldVal.date_format) {
          this.checkCustomDate();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.checkCustomDate();
  },
  props: {
    dateTimeFormats: {
      type: Object,
      default: () => ({
        date_formats: [],
        time_formats: []
      }),
      validator: function (value) {
        return (
          !value ||
          (Array.isArray(value.date_formats) &&
            Array.isArray(value.time_formats))
        );
      }
    },
    value: {
      type: Object,
      default: () => ({
        timezone: "UTC",
        date_format: "DD/MM/YYYY",
        time_format: "hh:mm A"
      }),
      validator_: function (value) {
        if (value === null) {
          return true;
        }
        return (
          typeof value === "object" &&
          typeof value.date_format === "string" &&
          typeof value.time_format === "string" &&
          typeof value.date_time_format === "string" &&
          typeof value.timezone === "string"
        );
      }
    }
  },
  computed: {
    combinedDateTimeFormat() {
      if (this.value) {
        let dateFormat = this.value.date_format;
        let timeFormat = this.value.time_format;
        if (this.value.date_format == "Custom") {
          dateFormat = this.value.custom_date;
        }
        if (this.value.time_format == "Custom") {
          timeFormat = this.value.custom_time;
        }
        return `${dateFormat} ${timeFormat}`;
      }
      return "";
    },
    showDateTimeFormats() {
      if (this.value) {
        const format = this.combinedDateTimeFormat;
        return `${format} (${this.formatDateTime(
          this.value.timezone,
          format
        )})`;
      }
      return "";
    },
    dateFormatOptions() {
      let options = [];
      if (this.dateTimeFormats && this.dateTimeFormats.date_formats) {
        options = this.dateTimeFormats.date_formats.map((format) => {
          return {
            key: format,
            value: this.formatDateTime(this.value.timezone, format)
          };
        });
      }

      const Custom = "---";

      options.push({
        key: "Custom",
        value: this.formatDateTime(this.value.timezone, Custom)
      });

      return options;
    },

    timeFormatOptions() {
      let options = [];
      if (this.dateTimeFormats && this.dateTimeFormats.time_formats) {
        options = this.dateTimeFormats.time_formats.map((format) => {
          return {
            key: format,
            value: this.formatDateTime(this.value.timezone, format)
          };
        });
      }

      const Custom = "---";

      options.push({
        key: "Custom",
        value: this.formatDateTime(this.value.timezone, Custom)
      });

      return options;
    }
  },
  methods: {
    checkCustomDate() {
      if (this.dateTimeFormats && this.value && this.value.date_format) {
        if (
          !this.dateTimeFormats.date_formats.includes(this.value.date_format)
        ) {
          this.value.custom_date = this.value.date_format;
          this.value.date_format = "Custom";
        }
      }
      if (this.dateTimeFormats && this.value && this.value.time_format) {
        if (
          !this.dateTimeFormats.time_formats.includes(this.value.time_format)
        ) {
          this.value.custom_time = this.value.time_format;
          this.value.time_format = "Custom";
        }
      }
    },
    formatDateTime(timeZone = "UTC", format, dateTime) {
      return moment.tz(dateTime || new Date(), timeZone).format(format);
    },
    saveForm() {
      this.value.date_time_format = this.combinedDateTimeFormat;
      this.$emit("input", this.value);
      this.$bvModal.hide("modal-date");
    }
  }
};
</script>
<style scoped lang="scss">
.save-btn {
  text-align: center;
}

.dark-layout {
  .date-input {
    border: 1px solid var(--darkgray);
  }
}
.date-input {
  height: 2.6rem;
  border: 1px solid var(--gray-605);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 5px;
  }
}

.option {
  min-width: calc(50vh - 50%);
  display: flex;
  justify-content: space-between;
}
.selected-option {
  min-width: calc(10vh - -75%);
  display: flex;
  justify-content: space-between;
}
.display-format {
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
  .selected-option {
    min-width: calc(10vh - -68%);
  }
}
</style>
<style lang="scss">
#modal-date___BV_modal_title_ {
  font-size: 20px;
  margin-left: auto;
}
</style>
